import { useContext, useState, useEffect } from "react";
import { HomeContext, PageContext } from "../../App";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import {
  NavLink,
  createSearchParams,
  useNavigate,
  Link,
} from "react-router-dom";
import { Squeeze, Squash } from "hamburger-react";

const Header = () => {
  const data = useContext(HomeContext);
  const pageData = useContext(PageContext);

  const headerSectionContent = data?.customization?.headerSection?.content;
  const headerSectionSty = data?.customization?.headerSection?.style;

  const isSmallScreen = window.innerWidth <= 767;
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const logo = data.logo_img;
  const logoHDef = "logo.svg";
  const headerBg = {
    boxShadow: isNavCollapsed ? "0 6px 8px 0 rgb(0 0 0 / 15%)" : "none",
    background: headerSectionSty?.backgroundColor,
  };
  const navlinkColor = {
    color: headerSectionSty.linkColor,
  };

  return (
    <header className="header" data-scroll>
      {["lg"].map((expand, index) => (
        <Navbar expand={expand} className="nav_bg header" style={headerBg}>
          <div className="container">
            <div className="nav_parent">
              <Navbar.Brand href="/" className="logoCenterText2">
                <div className="header_logo">
                  <img src={`static/assets/images/${"logo.svg"}`} alt="" />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle
                className="hamburger_main"
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
              >
                <div className="hamburger_sty">
                  <Squash
                    direction="right"
                    size={isSmallScreen ? 28 : 36}
                    color="#222"
                  />
                </div>
              </Navbar.Toggle>
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                {/* <Offcanvas.Header>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    ></Offcanvas.Title>
                  </Offcanvas.Header> */}
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <Link
                      to="/how-it-works"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + headerSectionSty.linkHoverColor;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + headerSectionSty.linkColor;
                      }}
                      style={navlinkColor}
                    >
                      How It Works
                    </Link>
                    <Link
                      style={navlinkColor}
                      to="/contact-us"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + headerSectionSty.linkHoverColor;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + headerSectionSty.linkColor;
                      }}
                    >
                      Contact Us
                    </Link>
                    <Link
                      style={navlinkColor}
                      to="/rate-and-fees"
                      onMouseEnter={(e) => {
                        e.target.style =
                          "color:" + headerSectionSty.linkHoverColor;
                      }}
                      onMouseLeave={(e) => {
                        e.target.style = "color:" + headerSectionSty.linkColor;
                      }}
                    >
                      Rate & Fees
                    </Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </div>
          </div>
        </Navbar>
      ))}
    </header>
  );
};

export default Header;
